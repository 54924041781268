<template>
  <v-expansion-panels class="hide-at-print" solo>
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate>
        <span class="header">Filter</span>
        <template v-slot:actions>
          <v-icon class="icon">mdi-filter-cog</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col>
            <v-slider
              v-model="filter.thresholdAverage"
              thumb-color="green"
              thumb-label="always"
              label="Mindesteintrageschwelle"
              :min="slideMin"
              :max="slideMax"
              @change="watchChanges"
            >
            </v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="Unternehmensgröße"
              v-model="filter.companySize"
              :items="optionsCompanySize"
              outlined
              @change="watchChanges"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="Region"
              v-model="filter.region"
              :items="optionsRegion"
              outlined
              @change="watchChanges"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="Bundesland"
              v-model="filter.federalState"
              :items="optionsFederalState"
              outlined
              @change="watchChanges"
            />
          </v-col>
        </v-row>
        <v-row v-if="showButton">
          <v-col>
            <v-btn color="primary" block @click="emitClick">Update</v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    slideMin: {
      default: 2,
      type: Number,
    },
    slideMax: {
      default: 50,
      type: Number,
    },
    showButton: {
      default: true,
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionsCompanySize: [
        {
          text: "Klein",
          value: "Small",
        },
        {
          text: "Mittel",
          value: "Medium",
        },
        {
          text: "Groß",
          value: "Big",
        },
        {
          text: "Alle",
          value: null,
        },
      ],
      optionsRegion: [
        {
          text: "Süd",
          value: "South",
        },
        {
          text: "Nord",
          value: "North",
        },
        {
          text: "Alle",
          value: null,
        },
      ],
      optionsFederalState: [
        {
          text: "Baden-Württemberg",
          value: "BW",
        },
        {
          text: "Bayern",
          value: "BY",
        },
        {
          text: "Berlin",
          value: "BE",
        },
        {
          text: "Brandenburg",
          value: "BB",
        },
        {
          text: "Bremen",
          value: "HB",
        },
        {
          text: "Hamburg",
          value: "HH",
        },
        {
          text: "Hessen",
          value: "HE",
        },
        {
          text: "Mecklenburg-Vorpommern",
          value: "MV",
        },
        {
          text: "Niedersachsen",
          value: "NI",
        },
        {
          text: "Nordrhein-Westfalen",
          value: "NW",
        },
        {
          text: "Rheinland-Pfalz",
          value: "RP",
        },
        {
          text: "Saarland",
          value: "SL",
        },
        {
          text: "Sachsen",
          value: "SN",
        },
        {
          text: "Sachsen-Anhalt",
          value: "ST",
        },
        {
          text: "Schleswig-Holstein",
          value: "SH",
        },
        {
          text: "Thüringen",
          value: "TH",
        },
        {
          text: "Alle",
          value: null,
        },
      ],
      filter: {
        thresholdAverage: 3,
        companySize: null,
        region: null,
        federalState: null,
      },
    };
  },
  methods: {
    watchChanges: function () {
      this.$emit("input", this.filter);
    },
    emitClick: function () {
      this.$emit("click");
    },
  },
  mounted: function () {
    this.$emit("input", this.filter);
  },
};
</script>

<style></style>
